import { Component, NgZone, OnInit } from '@angular/core';
import { FormioAppConfig } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { Formio } from '@formio/angular';
import { FormioResourceConfig } from '@formio/angular/resource';

@Component({

  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class DataUpdateGridComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public config: FormioAppConfig,
    public ngZone: NgZone,
    public configResources: FormioResourceConfig,
  ) { }

  //Row Data is returning a Promise not an Observable
  rowData: Promise<any>;
  autoGroupColumnDef = { minWidth: 200 };
  rowGroupPanelShow = 'always';
  defaultColDef = { flex: 1 };
  private gridApi;
  private gridColumnApi;
  public numRowsSelected: number = 0;

  //Color cells depending on status
  ragCellClassRules: any = {
    'rag-red': function (params) {
      return params.value === 'incomplete';
    },
    'rag-orange': function (params) {
      return params.value === 'pending';
    },
    'rag-yellow': function (params) {
      return params.value === 'warning';
    },
    'rag-pink': function (params) {
      return params.value === 'requiresReview';
    },
    'rag-green-blue': function (params) {
      return params.value === 'approved';
    },
    'rag-green': function (params) {
      return params.value === 'updateComplete';
    },
    'rag-green-light': function (params) {
      return params.value === 'validationComplete';
    }
  };

  columnDefs = [
    { field: "data.brandName", headerCheckboxSelection: true, headerName: "Brand Name", minWidth: 300, filter: true, sortable: true, checkboxSelection: true, enableRowGroup: true },
    {
      field: "data.status", onCellClicked: this.onSelect.bind(this), headerName: "Status", filter: true, sortable: true, cellClassRules: this.ragCellClassRules, enableRowGroup: true, cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result.split(",").join(" ").toUpperCase();
        } catch (error) { console.error('No Camel Case formatting params'); }
      }
    },
    { field: "data.publishAfter", headerName: "Publish After", filter: true, sortable: true, enableRowGroup: true },
    {
      field: "data.updateType", headerName: "Update Type", enableRowGroup: true, filter: true, sortable: true, cellRenderer: (params) => {
        if (params.value === 'price') {
          return `<i style="color:#9AF000;font-weight: bolder;font-size: x-large;" class="fa fa-dollar"></i>`;
        } else if (params.value === 'data') {
          return `<i style="color:#B610BF;font-weight: bolder;font-size: x-large;" class="fa fa-file"></i>`;
        } else {
          return params.value;
        }
      }
    },
    { field: "data.notes", headerName: "Notes", filter: false, sortable: false, minWidth: 300 },
    { field: "created", headerName: "Created On", filter: true, sortable: true }
  ];

  gridOps = {
    'url': `${this.config.apiUrl}/${this.configResources.name}/submission?limit=100000`,
    'method': 'GET'
  };

  countSelected($event) {
    let selected = this.gridApi.getSelectedRows();
    this.numRowsSelected = selected.length;
  }

  deleteSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  syncSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  onSelect(params) {
    let selected = params.api.getSelectedRows();
    this.ngZone.run(() => {
      this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
    });
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
