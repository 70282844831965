import { Component, AfterViewInit } from '@angular/core';
import { FormioAppConfig } from '@formio/angular';
import { PrismService } from '../Prism.service';
import { FormioAuthService } from '@formio/angular/auth';

@Component({
  selector: 'app-home',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements AfterViewInit {
  constructor(
    public config: FormioAppConfig,
    public prism: PrismService,
    public auth: FormioAuthService
  ) { }

  ngAfterViewInit() {
    this.prism.init();
  }
}
