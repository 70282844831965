import { Component, ViewEncapsulation, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormioAppConfig, FormioService, Formio } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioResourceConfig, FormioResourceIndexComponent, FormioResourceService, FormioResourceViewComponent, } from '@formio/angular/resource';

@Component({
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class SeoGridComponent extends FormioResourceIndexComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public appConfig: FormioAppConfig,
    public ngZone: NgZone,
    public service: FormioResourceService,
    public config: FormioResourceConfig,
    public cdr: ChangeDetectorRef
  ) { super(service, route, router, config, cdr, ngZone); }

  rowData: any;

  public autoGroupColumnDef = { minWidth: 200 };
  public rowGroupPanelShow = 'always';
  public defaultColDef = { flex: 1 };
  private gridApi;
  private gridColumnApi;
  public rowDataLength = 0;

  //Define the  rules for coloring the status and content status cellss
  statusCellClassRules: any = {
    'rag-red': function (params) {
      return params.value === 'inactive';
    },
    'rag-green': function (params) {
      return params.value === 'active';
    }
  };

  contentCellClassRules: any = {
    'rag-red': function (params) {
      return params.value === 'incomplete';
    },
    'rag-orange': function (params) {
      return params.value === 'pending';
    },
    'rag-yellow': function (params) {
      return params.value === 'warning';
    },
    'rag-pink': function (params) {
      return params.value === 'requiresReview';
    },
    'rag-green-blue': function (params) {
      return params.value === 'approved';
    },
    'rag-green': function (params) {
      return params.value === 'updateComplete';
    },
    'rag-green-light': function (params) {
      return params.value === 'validationComplete';
    },
    'rag-magenta': function (params) {
      return params.value === 'target';
    }
  };

  aggFuncs = {
    // this overrides the grids built-in sum function
    'Words': params => {
      let sum = 0;
      params.values.forEach(value => sum += parseInt(value));
      return sum;
    }
  };

  //Define the columns and the data property to be used in binding
  columnDefs = [
    { field: "data.keyword", headerName: "Keyword", filter: true, sortable: true, minWidth: 300 },
    { field: "data.wordCount", headerName: "Word Count", filter: true, sortable: true, aggFunc: 'Words' },
    {
      field: "data.contentDestination", headerName: "Destination", filter: true, sortable: true, enableRowGroup: true, cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result.split(",").join(" ").toUpperCase();
        } catch (error) { console.error('No Camel Case formatting params'); }
      }
    },
    {
      field: "data.activeStatus", headerName: "Status", filter: true, sortable: true, cellClassRules: this.statusCellClassRules, enableRowGroup: true, cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result.split(",").join(" ").toUpperCase();
        } catch (error) { console.error('No Camel Case formatting params'); }
      }
    },
    {
      field: "data.contentStatus", headerName: "Content", filter: true, sortable: true, cellClassRules: this.contentCellClassRules, enableRowGroup: true, cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result.split(",").join(" ").toUpperCase();
        } catch (error) { console.error('No Camel Case formatting params'); }
      }
    },
    { field: "data.postDateTime", headerName: "Post Date", filter: true, sortable: true },
    { field: "created", headerName: "Created On", filter: true, sortable: true, }
  ];

  //Used for the request to formio onInit to load the data
  gridOps = {
    'url': `${this.appConfig.apiUrl}/${this.config.form}/submission?limit=100000`,
    'method': 'GET'
  };

  //Function called when a cell is clicked. Provides an instance of the ag-grid api to get selected rows.
  onSelect(grid: any) {
    try {
      let selected = grid.api.getSelectedRows();
      this.ngZone.run(() => {
        this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
      });
    } catch (err) { console.log('No Row Selected') }
  }

  //Method called when the new or create button is clicked. Provides
  //Creation view and edit iew managed by formio resource componenets in the @formio/angular package
  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  //Requests the data and populates the table view when done. Used with async pipe in view component
  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
    let tempData = this.rowData;
    tempData.then((res) => { this.rowDataLength = res.length })
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
