import { Component, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormioAppConfig, FormioService, Formio } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioResourceConfig, FormioResourceIndexComponent, FormioResourceService, FormioResourceViewComponent, } from '@formio/angular/resource';

@Component({
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class CatalogGridComponent extends FormioResourceIndexComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public appConfig: FormioAppConfig,
    public ngZone: NgZone,
    public service: FormioResourceService,
    public config: FormioResourceConfig,
    public cdr: ChangeDetectorRef
  ) { super(service, route, router, config, cdr, ngZone); }

  rowData: any;
  autoGroupColumnDef = { minWidth: 200 };
  rowGroupPanelShow = 'always';
  defaultColDef = { flex: 1 };
  private gridApi;
  private gridColumnApi;
  public numRowsSelected: number = 0;


  //Define the columns and the data property to be used in binding
  columnDefs = [
    { field: "data.brandName1", headerCheckboxSelection: true, headerName: "Brand", filter: true, sortable: true, minWidth: 300, enableRowGroup: true, checkboxSelection: true },
    { field: "data.name", onCellClicked: this.onSelect.bind(this), headerName: "Name", filter: true, sortable: true },
    {
      field: "data.type", headerName: "Type", filter: true, sortable: true, enableRowGroup: true, cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result.split(",").join(" ").toUpperCase();
        } catch (error) { console.error('No Camel Case formatting params'); }
      }
    },
    { field: "created", headerName: "Created On", filter: true, sortable: true }
  ];

  //Used for the request to formio onInit to load the data
  gridOps = {
    'url': `${this.appConfig.apiUrl}/${this.config.form}/submission?limit=100000`,
    'method': 'GET'
  };

  deleteSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  syncSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  countSelected($event) {
    let selected = this.gridApi.getSelectedRows();
    this.numRowsSelected = selected.length;
  }

  onSelect(params) {
    let selected = params.api.getSelectedRows();
    this.ngZone.run(() => {
      this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
    });
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
