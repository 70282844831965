import { Component, AfterViewInit } from '@angular/core';
import { FormioAppConfig } from '@formio/angular';
import { PrismService } from '../Prism.service';
import { FormioAuthService } from '@formio/angular/auth';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  constructor(
    public config: FormioAppConfig,
    public prism: PrismService,
    public auth: FormioAuthService
  ) { }

  displayHomeHeader(e) {
    let target = e.target;
    target.style.display = 'none';
  }

  ngAfterViewInit() {
    this.prism.init();
  }
}
