import { Component, ViewEncapsulation, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormioAppConfig, FormioService, Formio } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioResourceConfig, FormioResourceIndexComponent, FormioResourceService, FormioResourceViewComponent, } from '@formio/angular/resource';

@Component({
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class StrainsGridComponent extends FormioResourceIndexComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public appConfig: FormioAppConfig,
    public ngZone: NgZone,
    public service: FormioResourceService,
    public config: FormioResourceConfig,
    public cdr: ChangeDetectorRef
  ) { super(service, route, router, config, cdr, ngZone); }

  rowData: any;

  autoGroupColumnDef = { minWidth: 200 };
  rowGroupPanelShow = 'always';
  defaultColDef = { flex: 1 };
  private gridColumnApi;
  private gridApi;
  public rowDataLength = 0;



  //Define the columns and the data property to be used in binding
  columnDefs = [
    { field: "data.name", headerName: "Name", filter: true, sortable: true, minWidth: 300 },
    { field: "data.type", headerName: "Type", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.rating", headerName: "Rating", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.onset", headerName: "Onset", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.effectDuration", headerName: "Duration", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.thc", headerName: "THC", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.cbd", headerName: "CBD", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.effects", headerName: "Effects", filter: true, sortable: true, enableRowGroup: true },
    { field: "data.flavor", headerName: "Flavors", filter: true, sortable: true, enableRowGroup: true },
    { field: "created", headerName: "Created On", filter: true, sortable: true, enableRowGroup: true }
  ];

  //Used for the request to formio onInit to load the data
  gridOps = {
    'url': `${this.appConfig.apiUrl}/${this.config.form}/submission?limit=100000`,
    'method': 'GET'
  };

  //Function called when a cell is clicked. Provides an instance of the ag-grid api to get selected rows.
  //TODO Allow multiple selection of checkboxes and single click of cells
  onSelect(grid: any) {
    try {
      let selected = grid.api.getSelectedRows();
      this.ngZone.run(() => {
        this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
      });
    } catch (err) { console.log('No Row Selected') }
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  //Method called when the new or create button is clicked. Provides
  //Creation view and edit iew managed by formio resource componenets in the @formio/angular package
  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  //Requests the data and populates the table view when done. Used with async pipe in view component
  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
    let tempData = this.rowData;
    tempData.then((res) => { this.rowDataLength = res.length })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
