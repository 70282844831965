import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Formio, FormioModule, FormioAppConfig } from '@formio/angular';
// import premium from '@formio/premium';
// Formio.use(premium);
import { FormioGrid } from '@formio/angular/grid';
import { FormioAuthService, FormioAuthConfig } from '@formio/angular/auth';
import { FormioResources } from '@formio/angular/resource';
import { PrismService } from './Prism.service';

import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

import { AppConfig } from './config';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { DataComponent } from './data/data.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { DataUpdateGridComponent } from './dataupdate/grid/grid.component';
import { ContactsGridComponent } from './contacts/grid/grid.component';
import { SeoGridComponent } from './seo/grid/grid.component';
import { CredentialsGridComponent } from './credentials/grid/grid.component';
import { CatalogGridComponent } from './catalog/grid/grid.component';
import { StrainsGridComponent } from './strains/grid/grid.component';
import { LegalGridComponent } from './legal/grid/grid.component';

// Make sure we use fontawesome everywhere in Form.io renderers.
(Formio as any).icons = 'fontawesome';
import { from } from 'rxjs';
import { SeoComponent } from './seo/seo.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DataComponent,
    AdminComponent,
    CatalogsComponent,
    DataUpdateGridComponent,
    ContactsGridComponent,
    SeoGridComponent,
    SeoComponent,
    CredentialsGridComponent,
    CatalogGridComponent,
    StrainsGridComponent,
    LegalGridComponent
  ],
  imports: [
    BrowserModule,
    AgGridModule.withComponents([]),
    CommonModule,
    FormioModule,
    FormioGrid,
    RouterModule.forRoot([
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'home',
        component: AdminComponent
      },
      {
        path: 'data',
        component: DataComponent
      },
      {
        path: 'catalogs',
        component: CatalogsComponent
      },
      {
        path: 'analytics',
        component: SeoComponent
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'seo',
        loadChildren: () => import('./seo/seo.module').then(m => m.SeoGridModule)
      },
      {
        path: 'catalog',
        loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule)
      },
      {
        path: 'dataupdate',
        loadChildren: () => import('./dataupdate/dataupdate.module').then(m => m.DataupdateModule)
      },
      {
        path: 'credentials',
        loadChildren: () => import('./credentials/credentials.module').then(m => m.CredentialsModule)
      },
      {
        path: 'strains',
        loadChildren: () => import('./strains/strains.module').then(m => m.StrainsModule)
      },
      {
        path: 'brand',
        loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'event',
        loadChildren: () => import('./event/event.module').then(m => m.EventModule)
      },
      {
        path: 'manager',
        loadChildren: () => import('./form/form.module').then(m => m.FormModule)
      }
    ], { useHash: true })
  ],
  providers: [
    PrismService,
    FormioAuthService,
    FormioResources,
    { provide: FormioAppConfig, useValue: AppConfig },
    {
      provide: FormioAuthConfig, useValue: {
        login: {
          form: 'user/login'
        },
        register: {
          form: 'user/register'
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
