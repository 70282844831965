import { Component, OnInit } from '@angular/core';
import { FormioAuthService } from '@formio/angular/auth';
import { FormioAppConfig } from '@formio/angular';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent {

  constructor(
    public config: FormioAppConfig,
    public auth: FormioAuthService) {
  }


}
