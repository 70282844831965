import { Component, ViewEncapsulation, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormioAppConfig, FormioService, Formio } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioResourceConfig, FormioResourceIndexComponent, FormioResourceService, FormioResourceViewComponent, } from '@formio/angular/resource';


@Component({
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class ContactsGridComponent extends FormioResourceIndexComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public appConfig: FormioAppConfig,
    public ngZone: NgZone,
    public service: FormioResourceService,
    public config: FormioResourceConfig,
    public cdr: ChangeDetectorRef
  ) { super(service, route, router, config, cdr, ngZone); }

  rowData: any;
  private gridColumnApi;
  private gridApi;
  public autoGroupColumnDef: any = { minWidth: 200 };
  public rowGroupPanelShow: string = 'always';
  public defaultColDef: any = { flex: 1 };
  public gridOptions: any;
  public rowDataLength: number = 0;


  //Define the columns and the data property to be used in binding
  columnDefs = [
    { field: "data.brandName", headerName: "Brand", filter: true, sortable: true, minWidth: 300, enableRowGroup: true },
    { field: "data.fullName", headerName: "Name", filter: true, sortable: true },
    { field: "data.title", headerName: "Title", filter: true, sortable: true },
    { field: "created", headerName: "Created On", filter: true, sortable: true }
  ];

  //Used for the request to formio onInit to load the data
  gridOps = {
    'url': `${this.appConfig.apiUrl}/${this.config.form}/submission?limit=250`,
    'method': 'GET'
  };

  //Function called when a cell is clicked. Provides an instance of the ag-grid api to get selected rows.
  //TODO Allow multiple selection of checkboxes and single click of cells
  onSelect(gridOptions: any) {
    try {
      let selected = gridOptions.api.getSelectedRows();
      this.ngZone.run(() => {
        this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
      });
    } catch (err) { console.log('No Row Selected') }
  }

  //Method for getting CSV data , will want to setup options

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  //Method called when the new or create button is clicked. Provides
  //Creation view and edit iew managed by formio resource componenets in the @formio/angular package
  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  //Requests the data and populates the table view when done. Used with async pipe in view component
  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
    let tempData = this.rowData;
    tempData.then((res) => { this.rowDataLength = res.length })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
