import { Component, ViewEncapsulation, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormioAppConfig, FormioService, Formio } from '@formio/angular';
import { FormioAuthService } from '@formio/angular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioResourceConfig, FormioResourceIndexComponent, FormioResourceService, FormioResourceViewComponent, } from '@formio/angular/resource';

@Component({
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})

export class LegalGridComponent extends FormioResourceIndexComponent implements OnInit {
  constructor(
    public auth: FormioAuthService,
    public route: ActivatedRoute,
    public router: Router,
    public appConfig: FormioAppConfig,
    public ngZone: NgZone,
    public service: FormioResourceService,
    public config: FormioResourceConfig,
    public cdr: ChangeDetectorRef
  ) { super(service, route, router, config, cdr, ngZone); }

  rowData: any;

  autoGroupColumnDef = { minWidth: 200 };
  rowGroupPanelShow = 'always';
  defaultColDef = { flex: 1, enableRowGroup: true, sortable: true, filter: true };
  private gridApi;
  private gridColumnApi;
  public numRowsSelected: number = 0;

  //Define the columns and the data property to be used in binding
  columnDefs = [
    {
      field: "data.state", headerName: "State", cellRenderer: (params) => {
        try {
          var result = params.value.replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          return result[0].toUpperCase() + result.slice(1);
        } catch (error) { console.error('No formatting params'); }
      }
    },
    {
      field: "data.currentStatus", headerName: "Status", cellRenderer: (params) => {
        try {
          var result = params.value[0].replace(/([A-Z]+)/g, ",$1").replace(/^,/, "");
          result = result.split(",").join(" ");
          return result.charAt(0).toUpperCase() + result.substr(1);
        } catch (error) { console.log(error); }
      }
    },
    {
      field: "data.financials[0].gdp", headerName: "GDP", cellRenderer: (params) => {
        console.log(params);
        try {
          return params.data.data.financials[0].gdp.toLocaleString("en-US");
        } catch (err) { console.log(err); }
      }
    },
    {
      field: "data.financials[0].population", headerName: "Population", cellRenderer: (params) => {
        try {
          return params.data.data.financials[0].population.toLocaleString("en-US");
        } catch (err) { console.log(err); }
      }
    }
  ];

  //Used for the request to formio onInit to load the data
  gridOps = {
    'url': `${this.appConfig.apiUrl}/${this.config.form}/submission?limit=100000`,
    'method': 'GET'
  };

  deleteSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  syncSelected() {
    let selectedRows = this.gridApi.getSelectedRows();
    alert(`You are going to delete ${selectedRows.length} rows... eventually when I code it up for you ;)`)
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }


  countSelected($event) {
    let selected = this.gridApi.getSelectedRows();
    this.numRowsSelected = selected.length;
  }

  //Function called when a cell is clicked. Provides an instance of the ag-grid api to get selected rows.
  onSelect(grid: any) {
    console.log(grid);
    try {
      let selected = grid.api.getSelectedRows();
      this.ngZone.run(() => {
        this.router.navigate([selected[0]._id, 'view'], { relativeTo: this.route });
      });
    } catch (err) { console.log(err) }
  }

  autosize() {
    this.gridColumnApi.autoSizeAllColumns();
  }

  onCreateItem() {
    this.ngZone.run(() => {
      this.router.navigate(['new'], { relativeTo: this.route });
    });
  }

  ngOnInit() {
    this.rowData = Formio.request(this.gridOps.url, this.gridOps.method);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
